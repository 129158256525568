import { useEffect } from "react";

import Head from "next/head";
import NextImage from "next/image";
import { useRouter } from "next/router";

import {
  ContentWrapper,
  ImageOverlay,
  ImageWrapper,
  MobileImageWrapper,
} from "./styles";

import { Button } from "~components/Button";
import { Form } from "~components/Form";
import { useAppConfig } from "~hooks/useAppConfig";
import { useIsomorphicLayoutEffect } from "~hooks/useIsomorphicLayoutEffect";
import { useSession } from "~hooks/useSession";
import { SolarzTypography } from "~solarzui/SolarzTypography";
import { Card, Divider, Flex } from "antd";

export default function Page() {
  const router = useRouter();

  const { changeTheme, screens } = useAppConfig();

  const {
    checkAuthenticationAndRedirect,
    onChangeToken,
    pathBeforeRedirect,
    sessionStatus,
  } = useSession();

  useEffect(() => {
    checkAuthenticationAndRedirect({
      ifCommonRedirectTo: pathBeforeRedirect ?? "/propostas",
      ifCommonDisplayMessage: "Bem-vindo(a) CRM da SolarZ 👋",
      ifAdminRedirectTo: "/admin/usuarios",
      ifAdminDisplayMessage: "Olá administrador(a) seja bem-vindo(a) 👋",
    });
  }, [checkAuthenticationAndRedirect, pathBeforeRedirect]);

  /*
    Save token from the url into the context
    and check if is a valid token & user.
  */
  useIsomorphicLayoutEffect(() => {
    const token = router.query?.token;

    if (!token || typeof token !== "string") return;

    router.replace("/", undefined, { shallow: true });

    onChangeToken(token);
  }, [router]);

  if (sessionStatus === "unauthenticated") {
    changeTheme("default");
  }

  if (!screens.desktop) {
    return (
      <Flex style={{ height: "100vh", overflow: "hidden" }}>
        <Head>
          <title>SolarZ CRM</title>
          <meta
            name="description"
            content="Transforme sua gestão de clientes com o CRM SolarZ! A solução perfeita para impulsionar suas vendas, aumentar a eficiência e fortalecer os relacionamentos. Simplifique seu processo de vendas e atendimento ao cliente com nossa plataforma intuitiva e poderosa. Descubra como a SolarZ pode ajudar sua empresa a brilhar ainda mais!"
          />
        </Head>

        <MobileImageWrapper>
          <ImageOverlay />

          <NextImage
            src="/images/solarz-crm-login-background.jpg"
            alt="Uma imagem representando a integração do CRM da SolarZ com energia solar, mostrando várias placas solares alinhadas. Na parte inferior, há o texto 'CRM - Customer Relationship Management', enquanto o logotipo da SolarZ está localizado no topo."
            priority
            width={1200}
            height={1024}
            style={{ objectFit: "fill" }}
          />
        </MobileImageWrapper>

        <Flex
          vertical
          justify="center"
          align="center"
          style={{ width: "100%", zIndex: 1 }}
        >
          <NextImage
            src="/images/logo-solarz-z.png"
            width={64}
            height={64}
            alt="Logo da SolarZ reduzida, mostrando somente a letra Z"
            style={{ marginBottom: "2rem" }}
          />

          <SolarzTypography
            hierarchy="h4"
            fontWeight="bold"
            variant="title"
            style={{ marginBottom: "0.75rem", color: "var(--gray-50)" }}
          >
            Seja bem-vindo
          </SolarzTypography>

          <SolarzTypography
            hierarchy="paragraph2"
            fontWeight="regular"
            variant="subTitle"
            style={{ marginBottom: "2rem", color: "var(--gray-200)" }}
          >
            Preencha seus dados para realizar o login
          </SolarzTypography>

          <Card
            style={{
              height: 412,
              width: "90vw",
              maxWidth: 380,
            }}
            styles={{
              body: {
                padding: 16,
              },
            }}
          >
            <Form.Login />

            <Divider style={{ marginBottom: "0.75rem" }}>
              <SolarzTypography hierarchy="paragraph2">Ou</SolarzTypography>
            </Divider>

            <Button.PipedriveLogin isBlock />
          </Card>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex style={{ height: "100vh", overflow: "hidden" }}>
      <Head>
        <title>SolarZ CRM</title>
        <meta
          name="description"
          content="Transforme sua gestão de clientes com o CRM SolarZ! A solução perfeita para impulsionar suas vendas, aumentar a eficiência e fortalecer os relacionamentos. Simplifique seu processo de vendas e atendimento ao cliente com nossa plataforma intuitiva e poderosa. Descubra como a SolarZ pode ajudar sua empresa a brilhar ainda mais!"
        />
      </Head>

      {screens.desktop && (
        <ImageWrapper>
          <NextImage
            src="/images/solarz-logo-white.png"
            width={220}
            height={56}
            alt="Logo da SolarZ em cor branca"
            className="logo"
          />

          <NextImage
            src="/images/solarz-crm-login-background.jpg"
            width={720}
            height={1024}
            alt="Uma imagem representando a integração do CRM da SolarZ com energia solar, mostrando várias placas solares alinhadas. Na parte inferior, há o texto 'CRM - Customer Relationship Management', enquanto o logotipo da SolarZ está localizado no topo."
            priority
            className="background"
          />

          <SolarzTypography
            hierarchy="title1"
            style={{
              position: "absolute",
              color: "var(--gray-200)",
              bottom: "4rem",
              left: "2.5rem",
            }}
          >
            CRM
          </SolarzTypography>
        </ImageWrapper>
      )}

      <Flex
        vertical
        justify="center"
        flex={1}
        align="center"
        style={{ zIndex: 1 }}
      >
        <ContentWrapper vertical>
          <SolarzTypography
            hierarchy="h3"
            fontWeight="bold"
            variant="title"
            style={{ marginBottom: "0.75rem" }}
          >
            Seja bem-vindo
          </SolarzTypography>

          <SolarzTypography
            hierarchy="paragraph2"
            fontWeight="regular"
            variant="subTitle"
            style={{ marginBottom: "2rem" }}
          >
            Preencha seus dados para realizar o login
          </SolarzTypography>

          <Form.Login />

          <Divider style={{ marginBottom: "1rem" }}>
            <SolarzTypography hierarchy="paragraph2">Ou</SolarzTypography>
          </Divider>

          <Button.PipedriveLogin isBlock />
        </ContentWrapper>
      </Flex>
    </Flex>
  );
}
