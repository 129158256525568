import styled from "@emotion/styled";
import { Flex } from "antd";

export const ImageWrapper = styled(Flex)`
  width: 90vw;
  max-width: 720px;
  height: 100%;

  img.background {
    width: 100%;
    height: 100%;
  }

  img.logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }

  @media (max-width: 1200px) {
    max-width: 50vw;
  }
`;

export const ContentWrapper = styled(Flex)`
  width: 90vw;
  max-width: 464px;

  /* FORCE LIGHT COLORS  */
  html.dark & {
    .ant-input-affix-wrapper {
      background-color: var(--white) !important;

      input {
        color: var(--gray-900);
      }
    }

    .ant-typography[variant="title"] {
      color: var(--gray-700) !important;
    }

    .ant-typography[variant="subtitle"] {
      color: var(--gray-600) !important;
    }

    .ant-typography[hierarchy="paragraph2"][variant="default"] {
      color: var(--gray-500) !important;
    }

    .ant-divider::before {
      background: var(--gray-300);
    }

    .ant-divider::after {
      background: var(--gray-300);
    }

    .solarz-form-item label {
      color: var(--gray-700) !important;
    }

    > button {
      background-color: var(--white);

      &:not(:disabled):hover {
        background-color: var(--gray-50);
      }
    }
  }
`;

export const MobileImageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.56);
  width: 100%;
  height: 100%;
`;
